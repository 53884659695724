var world = this;

var owl = $('.owl-carousel');


$(document).keyup(function(e) {
    if (e.keyCode === 27){
    $('.our-team-active').removeClass('our-team-active');
    }  
    
});

$( document ).ready(function() {
  
  owl.on('translated.owl.carousel', function(event) {
    if( event.item.index == 0 ){

      $('.prev').addClass('desactive');
      $('.next').removeClass('desactive');
    
    }else{
    
      var currnetel = event.item.index + event.page.size;
      // last element
      if( currnetel ==  event.item.count ){
        $('.next').addClass('desactive');
        $('.prev').removeClass('desactive');
      }else{
        $('.next').removeClass('desactive');
        $('.prev').removeClass('desactive');
      }
    }	
  })

  owl.owlCarousel({
    dot:true,
    margin:0,
    nav:true,
    loop:false,
    translated: {
      
    },
    onRefresh : function(event){
      $(".target-dots").html("");
      $(".clear").find(".active").find(".owl-dots").clone().appendTo(".target-dots");
    },
    onInitialized : function(event){
      $(".target-dots").html("");
      $(".clear").find(".active").find(".owl-dots").clone().appendTo(".target-dots");
    },
    onTranslated : function(event){
      $(".target-dots").html("");
      $(".clear").find(".active").find(".owl-dots").clone().appendTo(".target-dots");
    },
    onChange: function (event) {
      if(window.screen.width > 500){
        if(event.property.name =='settings'){
          let count = event.item.count;     
          let index = event.item.index;
          if(count < 4 ){
            $('.gallery-buttons').fadeOut( 'slow' );	
          } else{
          $('.gallery-buttons').fadeIn( 'slow' );	
          }
      }
    }
    },
  responsive:{
              320:{
                  items:1,
                  nav: true,
                  navText: '',
              },
              500:{
                  items:1,
                  nav: true,
                  navText: '',
              },
              768:{
                  items:2,
                  nav: true,
                  navText: '',
    },
              992:{
                  items:3,
                  nav: true,
                  navText: '',
    }
  } });
  
  var $endmessage = '<div class=\'content-message\'><h2>Check back regularly for the most recent media</h2>	</div>' ;
  $( '.end-message' ).append($endmessage);

});

// Go to the next item
$('.next').click(function() {
    owl.trigger('next.owl.carousel');

  
})
// Go to the previous item
$('.prev').click(function() {
    // With optional speed parameter
    // Parameters has to be in square bracket '[]'
    owl.trigger('prev.owl.carousel', [300]);

})


$( '.team-content' ).click(function() {

    showFullScreenTeam($(this).data('id'));

});

$( '.close-button' ).click(function() {
  let idElemenToClose = $(this).attr('data-value');
  $('#' + idElemenToClose).removeClass('our-team-active');

});
owl.on('changed.owl.carousel', function(event) {
  
})
function showFullScreenTeam(id){
 
  if($('#' + id).length > 0 ){
    $('#' + id).addClass('our-team-active');

  }

}

$( ".active" ).on(function() {
  $(".target-dots").html("");
  $(".clear").find(".active").find(".owl-dots").clone().appendTo(".target-dots");
});
